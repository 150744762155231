import Api from './Api'

export default {
    updateSeatStatus(payload) {
        return Api().put('/update-seat-status', payload)
    },
    getCashInput() {
        return Api().get(`/cashinput`)
    },
    getCashDenominations() {
        return Api().get(`/cashdenominations`)
    },
    onCloseShift(payload) {
        return Api().post(`/shift_close`, payload)
    },
    getMovieDetails(payload) {
        return Api().post('/getMovieDetails', payload)
    },
    getOccupancyStatus(payload) {
        return Api().post('/getOccupancyStatus', payload)
    },
    getActiveScreens() {
        return Api().get('/getActiveScreens')
    },
    getRefundReason(payload) {
        return Api().post('/refund', payload)
    },
    updateRefundStatus(payload) {
        return Api().put('/refund', payload)
    },
    updateFloatAmountRecord(payload) {
        return Api().put('/cashinput', payload)
    },
    getAllShows(payload) {
        return Api().post('/getAllShows', payload)
    },
    getSeatLayout(payload) {
        // return Api().post('/getSeatLayout', payload)
        // getYKSeatLayout
        return Api().post('/getYKSeatLayout', payload)
    },
    getSeatType(payload) {
        return Api().get(`/api/admin/tablelist/screen_seat_type?sst_is_active=Y&screen_id=${payload.screen_id}`)

    },
    getAllDates() {
        return Api().get('/getAllDates')
    },
    reserveSeat(payload) {
        return Api().post('/reserveSeat', payload)
    },
    show_bookings(payload) {
        return Api().post('/show_bookings', payload)
    },
    show_seat_bookings(payload) {
        return Api().post('/show_seat_bookings', payload)
    },
    updateBookingStatus(payload) {
        return Api().post('/updateBookingStatus', payload)
    },
    getBookingDetails(payload) {
        return Api().post('/getBookingDetails', payload)
    },
    pickupTicket(payload) {
        return Api().post('/pickup-ticket', payload)
    },
    getAdditionalCharges(payload) {
        return Api().post('/get-additioanl-charges', payload)
    },

    // POS 
    PosBoxOfficeTransactions(payload) {
        return Api().post(`/pos/admin/transactions`, payload)
    },
    PosGetSeatLayout(payload) {
        return Api().post('/v1.1/getSeatLayout', payload)
    },
}