import PosService from '@/services/PosService';
import MasterService from '@/services/MasterService';
import {
  groupBy
} from "lodash";
import {
  exists,
  stat
} from 'fs';
import vue from 'vue';
import moment from "moment";
import VueSocketIO from 'vue-socket.io'
import store from '@/store';

function initialState() {
  return {
    seatNames: "",
    dates: [],
    currentDate: moment().format('ddd DD MMM'),
    currentTime: moment().format('LT'),
    selectedDate: moment().format("DD-MM-YYYY"),
    showTimes: [],
    userINFO: [],
    filteredShowTimes: [],
    selectedFormat: "",
    visibleShowsDates: [],
    visibleShowsScreens: [],
    visibleShowsLanguage: [],
    seatLayoutData: [],
    additionalCharges: [],
    seatsSelected: [],
    scheduleShowId: "",
    totalAmount: 0,
    cashInput: 0,
    netAmount: 0,
    totalCashCollected: 0,
    totalCardAmount: 0,
    showBookingId: "",
    currentScreen: "",
    showDetails: {},
    offerApplied: {},
    bookingDetails: {},
    ticketakDetails: {},
    shiftDetails: {},
    seatOccupancy: [],
    screens: [],
    movieDetails: {
      lang_name: null,
      md_thumbnail_url: null,
      movie_cast_crew: [],
      mf_name: null,
      // 
      mc_plot: null,
      mc_title: null,
      mrrdr_release_date: null,
      rating: null,
      mrrdr_runtime: null,

      // 
      movie_cast_crew: [],
      // 
      movie_production: [],
    },
    enterTicketDetails: true,
    showTicketInfo: false,
    ChangeTemplateFlag: {
      movieDetail: true,
      seatLayout: false,
      checkout: false
    },
    currency: '',

    // 
    ActualTotalAmount: 0,
    ActualNetTotalAmount: 0,

    totalNumberOfSeats: 0,
    prevSeatType: '',
    reservationId: '',
    selectedPaymentMode: {},
    paymentModes: [],
    HardwareName: ''
  }
}
const state = initialState()

const mutations = {
  SET_HARDWARE_NAME: (state, payload) => {
    state.HardwareName = payload.hs_hardware_name;
  },
  ON_RESET_DATE_STORE_STATE: (state, payload) => {
    // state = initialState();
    const s = initialState()
    Object.keys(s).forEach(key => {
      if ('movieDetails' !== key && 'dates' !== key && 'showTimes' !== key && 'selectedDate' !== key && 'visibleShowsDates' !== key && 'visibleShowsScreens' !== key && 'visibleShowsLanguage' !== key) {
        state[key] = s[key]
      }
    })
  },
  SET_TEMPLATES_FLAG: (state, payload) => {
    state.ChangeTemplateFlag = payload;
  },
  SET_CURRENCY: (state, payload) => {
    state.currency = payload;
  },
  SET_SHIFT_DETAILS: (state, payload) => {
    state.shiftDetails = payload;
  },
  SET_EMPTY_SEAT_BOOKING_DETAILS: (state, payload) => {
    state.seatsSelected = [];
    state.additionalCharges = [];
    state.totalAmount = 0;
    state.netAmount = 0;
    state.offerApplied = {};
  },
  SET_EMPTY_DATA: (state, payload) => {
    state.seatLayoutData = [];
    state.scheduleShowId = "";
    state.cashInput = 0;
    state.showBookingId = "";
    state.showBookingId = "";
    state.showDetails = {};
    state.totalAmount = 0;
    state.offerApplied = {};
    state.netAmount = 0;
    state.seatsSelected = []
    state.selectedSeats = []
    state.bookingDetails = {};
    state.ticketakDetails = {};
    state.additionalCharges = [];
  },
  GET_CASH_INPUT: (state, payload) => {
    state.cashInput = payload;
  },
  GET_TOTAL_CASH_COLLECTED: (state, payload) => {
    state.totalCashCollected = payload;
  },
  GET_TOTAL_CARD_AMOUNT: (state, payload) => {
    state.totalCardAmount = payload;
  },
  CURRENT_TIME: (state, payload) => {
    state.currentTime = moment().format('LT');
  },
  SET_SCREENS: (state, payload) => {
    state.screens = payload.Records;
  },
  TICKET_DETAILS: (state, payload) => {
    console.log('payload :::::', payload);
    if (payload.status) {
      state.ticketakDetails = payload.Records[0];
      state.ticketakDetails['sb_qr_code'] = 'http://api.qrserver.com/v1/create-qr-code/?data=' + payload.Records[0].sb_qr_code + '&size=100x100';
      state.ticketakDetails['show_date'] = moment(payload.Records[0].ss_start_date).format("ddd MMM Do, YY");
      state.enterTicketDetails = false
      state.showTicketInfo = true
    } else {
      vue.toasted.show("No Booking Details found for booking id");
    }

  },
  SHOW_BOOKING_DETAILS: (state, payload) => {
    state.bookingDetails = payload.Records[0];
    state.bookingDetails['seats'] = payload.Records[0].seats.join(', ')
    state.bookingDetails['sb_qr_code'] = 'http://api.qrserver.com/v1/create-qr-code/?data=' + payload.Records[0].sb_qr_code + '&size=100x100';
    state.bookingDetails['show_date'] = moment(payload.Records[0].ss_start_date).format("ddd, Do MMM, YYYY");
    state.bookingDetails['og_show_date'] = payload.Records[0].ss_start_date
    state.bookingDetails['ss_start_show_time'] = payload.Records[0]['ss_start_show_time'] ? moment(`${moment(new Date()).format('DD-MM-YYYY')} ${payload.Records[0].ss_start_show_time}`, 'DD-MM-YYYY HH:mm').format('h:mm A') : '';
  },
  SET_SHOW_DETAILS: (state, payload) => {
    console.log('payload :', payload);
    state.showDetails = payload;
    state.showDetails['og_ss_start_date'] = payload.ss_start_date;
    // console.log('payload.ss_start_date :', payload.actualDate);
    state.showDetails['ss_start_date'] = moment(payload.actualDate).format("ddd, Do MMM, YYYY");
    state.showDetails['ss_start_show_time'] = payload['ss_start_show_time'] ? moment(`${moment(new Date()).format('DD-MM-YYYY')} ${payload.ss_start_show_time}`, 'DD-MM-YYYY HH:mm').format('h:mm A') : '';

    // tConvert(payload.ss_start_show_time);

    // function tConvert(time) {
    //     // Check correct time format and split into components
    //     time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    //     if (time.length > 1) { // If time format correct
    //         time = time.slice(1);  // Remove full string match value
    //         time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    //         time[0] = +time[0] % 12 || 12; // Adjust hours
    //     }
    //     return time.join(''); // return adjusted time or original string
    // }

    //   tConvert (payload.ss_start_show_time);
  },
  SET_SELECTED_DATE: (state, payload) => {
    state.selectedDate = payload
    // const pages = Object.assign(payload.pages, payload.items);
    // state.paging = Object.assign({}, pages);
  },
  SET_FORMAT: (state, payload) => {
    state.selectedFormat = payload
    if (payload !== 'All Formats') {
      state.filteredShowTimes = state.showTimes.filter(x => {
        if (x.movie_format == payload) {
          return x
        }
      })
    } else {
      state.filteredShowTimes = state.showTimes;
    }
  },
  SET_ALL_DATES: (state, payload) => {
    state.dates = payload.Records;
    // state.dates.push(moment().format('DD-MM-YYYY'));
    // dates.map(x => {
    //   if (moment(x).isAfter(moment().format('DD-MM-YYYY'))) {
    //     state.dates.push(x);
    //   }
    // })

    function parseDate(dateStr) {
      var date = dateStr.split('-');
      var day = date[0];
      var month = date[1] - 1; //January = 0
      var year = date[2];
      return new Date(year, month, day);
    }

  },
  SET_ALL_SHOWS: (state, payload) => {
    state.userINFO = payload._result;
    if (payload != 'filter') {
      state.showTimes = payload.Records;
      state.filteredShowTimes = payload.Records;
    }
    //visibleShowsDates
    let showData_Date = groupBy(state.filteredShowTimes, "md_id");
    let DatestempArray = []
    for (let key in showData_Date) {
      if (showData_Date.hasOwnProperty(key)) {
        DatestempArray.push(showData_Date[key]);
      }
    }
    state.visibleShowsDates = DatestempArray


    //visibleShowsScreens
    let showData_Screen = groupBy(state.filteredShowTimes, "screen_id");
    let tempArray = []
    for (let key in showData_Screen) {
      if (showData_Screen.hasOwnProperty(key)) {
        tempArray.push(showData_Screen[key]);
      }
    }
    let finalTempArray = [];
    tempArray.map((item, index) => {
      let tempArray1 = []
      let newtempArray1 = groupBy(item, "md_id");

      for (let key in newtempArray1) {
        if (newtempArray1.hasOwnProperty(key)) {
          tempArray1.push(newtempArray1[key]);
        }
      }
      finalTempArray.push(tempArray1)

    })
    // console.log('state.visibleShowsScreens :', state.visibleShowsScreens);
    state.visibleShowsScreens = finalTempArray;


    //visibleShowsLanguage
    let showData_language = groupBy(state.filteredShowTimes, "language_name");
    let _tempArray = []
    for (let key in showData_language) {
      if (showData_language.hasOwnProperty(key)) {
        _tempArray.push(showData_language[key]);
      }
    }
    let _finalTempArray = [];
    _tempArray.map((item, index) => {
      let _tempArray1 = []
      let _newtempArray1 = groupBy(item, "md_id");

      for (let key in _newtempArray1) {
        if (_newtempArray1.hasOwnProperty(key)) {
          _tempArray1.push(_newtempArray1[key]);
        }
      }
      _finalTempArray.push(_tempArray1)

    })
    // console.log('_finalTempArray :', _finalTempArray);
    state.visibleShowsLanguage = _finalTempArray;
  },
  GET_ADITIONAL_CHARGES: (state, payload) => {
    state.additionalCharges = payload.Additional_Charges;
    // if (payload.totalAmount) {
    //   state.totalAmount = payload.totalAmount;
    // }
  },
  ON_REMOVE_ALL_SELECTED_SEATS: () => {
    state.seatLayoutData.map((x) => {
      x.seatsFinal.map((y) => {
        y.map((z) => {
          let selectedSeatsIndex = state.seatsSelected.findIndex((n) => n.sl_id === z.sl_id);
          // console.log('selectedSeatsIndex :', selectedSeatsIndex);
          if (selectedSeatsIndex !== -1) {
            z.selectStatus = !z.selectStatus;
            state.seatsSelected.splice(selectedSeatsIndex, 1)
          }
        })
      })
    });
    state.totalAmount = 0;
    state.netAmount = 0;
    // console.log('object :', object);
  },
  SELECTED_SEATS: (state, payload) => {
    console.log('payload :', payload);
    //If seat already selected, remove, else add

    // state.seatLayoutData[payload.classIndex].seatsFinal[payload.rowIndex][payload.seatIndex].selectStatus = !state.seatLayoutData[payload.classIndex].seatsFinal[payload.rowIndex][payload.seatIndex].selectStatus;
    if (state.seatLayoutData[payload.classIndex].seatsFinal[payload.rowIndex][payload.seatIndex].selectStatus == false) {
      console.log("else");
      //find and remove
      // state.seatsSelected.map((singleSeat)=>{
      // })
      // state.seatsSelected = state.seatsSelected.filter(function (singleSeat, index, arr) {
      //     return !(singleSeat.sl_id == payload.seat.sl_id)

      // });
      state.totalAmount = 0;
      state.netAmount = 0;
      state.seatsSelected.map(x => {
        state.totalAmount = state.totalAmount + x.seat_price
        state.netAmount = state.netAmount + x.seat_net_price
      })


      // console.log('1: if state.netAmount :', state.netAmount);


    }
    if (state.seatLayoutData[payload.classIndex].seatsFinal[payload.rowIndex][payload.seatIndex].selectStatus == true) {
      console.log('if :');
      // state.seatsSelected = state.seatsSelected.filter(function (singleSeat, index, arr) {
      //     return !(singleSeat.sl_id == payload.seat.sl_id)
      // });
      // console.log('payload.seat :', payload.seat);
      if (payload.seat.is_booked == 'Y') {
        payload.seat.seat_price = 0.00
        payload.seat.seat_net_price = 0.00
        // state.seatsSelected.push(payload.seat)
        state.netAmount = 0;
        state.totalAmount = 0;
        state.seatsSelected.map(x => {
          // console.log(' state.netAmount + x.seat_net_price :', state.netAmount, x.seat_net_price);
          state.netAmount = 0.00
          state.totalAmount = 0.00
        })
        console.log('state.seatsSelected if:', state.seatsSelected);


      } else {

        // state.seatsSelected.push(payload.seat)
        state.netAmount = 0;
        state.totalAmount = 0;
        state.seatsSelected.map(x => {
          // console.log(' state.netAmount + x.seat_net_price :', state.netAmount, x.seat_net_price);
          state.netAmount = state.netAmount + x.seat_net_price
          state.totalAmount = state.totalAmount + x.seat_price
        })
        console.log('state.seatsSelected else:', state.seatsSelected);

      }
      // console.log('ifstate.netAmount :', state.netAmount);

    }
    let seatsArray = "";
    for (let i in state.seatsSelected) {
      console.log('i :', state.seatsSelected[i].sl_seat_name);
      seatsArray += " " + state.seatsSelected[i].sl_seat_name
    }
    store.dispatch("onSetAndSendLog", {
      action: `Selected seats are : ${seatsArray} `,
      page_name: "pos booking",
      api_name: ""
    });


    // console.log('state.netAmount :', state.netAmount);
  },
  SEAT_SELECTED_TRUE: (state, payload) => {
    payload.seat.selectStatus = true
    payload.seat['prev_seat_id'] = payload.seat.seat_reserve_type_id;
    // console.log('payload.seat true: ', payload.seat['prev_seat_id']);/
    payload.seat.seat_reserve_type_id = payload.seattype.value
  },
  SEAT_SELECTED_FALSE: (state, payload) => {
    payload.seat.selectStatus = false
    console.log('payload.seat false: :', payload.seat['prev_seat_id']);
    payload.seat.seat_reserve_type_id = payload.seattype.value
  },
  SET_SEAT_LAYOUT: (state, payload) => {
    state.scheduleShowId = payload.seats.schedule_show_id;
    let seatData = [];
    let seatData1 = groupBy(payload.seats.Records, "sst_seat_type");
    payload.seatType.Records = payload.seatType.Records.sort(function (a, b) {
      return a.sst_order - b.sst_order
    });
    payload.seatType.Records.map((type) => {
      for (var key in seatData1) {
        if (seatData1.hasOwnProperty(key)) {
          // console.log(key + " -> " + seatData1[key]);
          if (key == type.sst_seat_type) {
            seatData[key] = seatData1[key]
          }
        }
      }
      // seatData.seat
    })
    let tempArray = [];
    for (let key in seatData) {
      if (seatData.hasOwnProperty(key)) {
        let seat_price = 0;
        let seat_price_obj = seatData[key].filter(x => {
          return x
        });
        // console.log('seat_price_obj :', seat_price_obj);
        if (seat_price_obj) {
          seat_price = seat_price_obj[0].seat_price;
        }
        // console.log('seat_price :', seat_price);
        tempArray.push({
          seatType: key,
          seat_price: seat_price,
          seats: seatData[key]
        });
      }
    }
    // let finalTempArray = [];
    // tempArray.map(item=>{
    //   let tempArray1 = [];
    //   let newtempArray =groupBy(item,"")
    // })
    let finalArray = [];

    // [{
    //   seatType: 'gold',
    //   seats: []
    // }]

    for (let i = 0; i < tempArray.length; i++) {
      let singleSeatType = tempArray[i];

      let seatData_1 = groupBy(singleSeatType.seats, "sl_row_num");
      // seatData_1 = {
      //   0: [],
      //   1: []
      // }
      let tempArray_1 = [];
      for (let key in seatData_1) {
        if (seatData_1.hasOwnProperty(key)) {
          let seatData_2 = seatData_1[key].map((singleSeat) => {
            singleSeat.selectStatus = false;
            singleSeat.SelectStatusFlag = false;
            return singleSeat
          })
          seatData_2 = seatData_2.sort(function (a, b) {
            return a.sl_col_num - b.sl_col_num
          });

          tempArray_1.push(seatData_2);
        }
      }
      // console.log('tempArray_1 before', tempArray_1)

      // console.log('tempArray_1 after', tempArray_1)
      // tempArray_1 = [[], []]
      tempArray[i].seatsFinal = tempArray_1;
      // finalArray.push({ seatType: tempArray, seats: tempArray_1 })
    }
    if (state.totalNumberOfSeats == 0) {
      vmVue.$root.$emit('bv::show::modal', 'seat_select_modal');
    }
    state.seatLayoutData = tempArray;
  },
  SHOW_BOOKING_ID: (state, payload) => {
    console.log('payload :', payload.Records);
    state.showBookingId = payload.Records;
  },
  CURRENT_SCREEN: (state, payload) => {
    state.currentScreen = payload
  },
  SET_MOVIE_DETAILS: async (state, payload) => {
    Object.assign(state.movieDetails, payload.Records[0]);
  },
  SET_SEAT_OCCUPANCY: (state, payload) => {
    let movieData = groupBy(payload.Records, "md_id");
    state.seatOccupancy = []
    let tempArray = [];
    for (let key in movieData) {
      if (movieData.hasOwnProperty(key)) {
        tempArray.push(movieData[key]);
      }
    }
    state.seatOccupancy = tempArray;
    store.dispatch("onSetAndSendLog", {
      action: `SEAT OCCUPANCY FOR : ${state.seatOccupancy} `,
      page_name: "pos booking",
      api_name: ""
    });
  },
  GET_SCHEDULE_SHOW_ID: (state, payload) => {
    state.scheduleShowId = payload;
  },
  //for offers
  GET_TOTAL_AMOUNT: (state, payload) => {
    //For Gross Amount
    state.offerApplied = payload;
    if (payload.offer_applicable_on_gross_amount == 'Y') {
      if (payload.offer_type_id == 6) {
        if (payload.pcs_offer_value) {
          if (payload.pcs_offer_value_is_percentage == 'N') {
            state.totalAmount = parseFloat(state.totalAmount) - parseFloat(payload.pcs_offer_value)
            state.offerApplied['oc_discount_value'] = parseFloat(payload.pcs_offer_value);
          } else if (payload.pcs_offer_value_is_percentage == 'Y') {
            state.totalAmount -= (parseFloat(state.totalAmount) * parseInt(payload.pcs_offer_value)) / 100
            state.offerApplied['oc_discount_value'] = (parseFloat(state.totalAmount) * parseInt(payload.pcs_offer_value)) / 100;
          }
        }
      } else {
        if (payload.oc_get_free_ticket) {
          // state.seatsSelected.map((x, index) => {
          // if (index < payload.oc_get_free_ticket) {
          state.totalAmount = parseFloat(state.totalAmount) - (parseFloat(state.seatsSelected[0].seat_price) * payload.oc_get_free_ticket)
          state.offerApplied['oc_discount_value'] = (parseFloat(state.seatsSelected[0].seat_price) * payload.oc_get_free_ticket);
          state.offerApplied['oc_discount_value_is_percentage'] = 'N'
          // }
          // })
        } else if (payload.oc_discount_value) {
          if (payload.oc_discount_value_is_percentage == 'N') {
            state.totalAmount = parseFloat(state.totalAmount) - parseFloat(payload.oc_discount_value)
            state.offerApplied['oc_discount_value'] = parseFloat(payload.oc_discount_value);
          } else if (payload.oc_discount_value_is_percentage == 'Y') {
            state.totalAmount -= (parseFloat(state.totalAmount) * parseInt(payload.oc_discount_value)) / 100
            state.offerApplied['oc_discount_value'] = (parseFloat(state.totalAmount) * parseInt(payload.oc_discount_value)) / 100;
          }
        }
      }
    }
    //For Net Amount
    else if (payload.offer_applicable_on_net_amount == 'Y') {
      if (payload.offer_type_id == 6) {
        if (payload.pcs_offer_value) {
          if (payload.pcs_offer_value_is_percentage == 'N') {
            state.totalAmount = parseFloat(state.totalAmount) - parseFloat(payload.pcs_offer_value)
            state.offerApplied['oc_discount_value'] = parseFloat(payload.pcs_offer_value);
          } else if (payload.pcs_offer_value_is_percentage == 'Y') {
            state.totalAmount -= (parseFloat(state.totalAmount) * parseInt(payload.pcs_offer_value)) / 100
            state.offerApplied['oc_discount_value'] = (parseFloat(state.totalAmount) * parseInt(payload.pcs_offer_value)) / 100;
          }
        }
      } else {
        if (payload.oc_get_free_ticket) {
          // state.seatsSelected.map((x, index) => {
          // if (index < payload.oc_get_free_ticket) {
          state.totalAmount = parseFloat(state.totalAmount) - (parseFloat(state.seatsSelected[0].seat_net_price) * payload.oc_get_free_ticket)
          // }
          state.offerApplied = payload;
          state.offerApplied['oc_discount_value'] = (parseFloat(state.seatsSelected[0].seat_net_price) * payload.oc_get_free_ticket);
          state.offerApplied['oc_discount_value_is_percentage'] = 'N'
          // })
        } else if (payload.oc_discount_value) {
          if (payload.oc_discount_value_is_percentage == 'N') {
            state.totalAmount = parseFloat(state.totalAmount) - parseFloat(payload.oc_discount_value);
            state.offerApplied['oc_discount_value'] = parseFloat(payload.oc_discount_value);
          } else if (payload.oc_discount_value_is_percentage == 'Y') {
            state.totalAmount -= (parseFloat(state.totalAmount) * parseInt(payload.oc_discount_value)) / 100;
            state.offerApplied['oc_discount_value'] = (parseFloat(state.totalAmount) * parseInt(payload.oc_discount_value)) / 100;
          }
        }
      }
    }
    console.log('state.offerApplied :', state.offerApplied);
    store.dispatch("onSetAndSendLog", {
      action: `TOTAL Amount of this selection was : ${state.totalAmount} `,
      page_name: "pos booking",
      api_name: ""
    });
  },
  GET_OFFER: (state, payload) => {
    state.offerApplied = payload;
  },
  SELECT_SEAT_HANDLDER: (state, payload) => {
    let { seat, rowIndex, seatIndex, originalIndex, backward, e, type, scheduleShowId } = payload;
    handlerSeatSelected(seat, rowIndex, seatIndex, originalIndex, backward, e, type);
    function handlerSeatSelected(seat, rowIndex, seatIndex, originalIndex, backward, e, type) {
      let firstTime = 0;
      if (!originalIndex) {
        firstTime = 1;
        originalIndex = seatIndex;
      } else if (state.totalNumberOfSeats != state.seatsSelected.length) {
        state.seatLayoutData[type].seatsFinal[rowIndex][seatIndex].selectStatus = true;
      }
      if (state.seatLayoutData[type].seatsFinal[rowIndex][seatIndex].selectStatus == false) {
        if (state.totalNumberOfSeats != state.seatsSelected.length) {
          state.seatLayoutData[type].seatsFinal[rowIndex][seatIndex].selectStatus = true;
        }
      }

      if (
        state.totalNumberOfSeats == state.seatsSelected.length &&
        firstTime == 1
      ) {
        //deselect all
        console.log('state.seatsSelected :', state.seatsSelected);
        state.seatsSelected.map(x => {
          console.log('type :', type);
          console.log('state.prevSeatType :', state.prevSeatType);
          console.log('x :', x);
          if (x.sst_id != seat.sst_id) {
            state.seatLayoutData[state.prevSeatType].seatsFinal[x.rowIndex][x.seatIndex].selectStatus = false;
            state.seatLayoutData[state.prevSeatType].seatsFinal[x.rowIndex][x.seatIndex].SelectStatusFlag = false;
          } else {
            state.seatLayoutData[type].seatsFinal[x.rowIndex][x.seatIndex].selectStatus = false;
            state.seatLayoutData[type].seatsFinal[x.rowIndex][x.seatIndex].SelectStatusFlag = false;
          }
        });
        // state.seatsSelected = [];
      }

      if (state.seatLayoutData[type].seatsFinal[rowIndex][seatIndex]['SelectStatusFlag'] == false) {
        //check if seat is selected, if yes, do nothing else
        //check how many selected
        if (parseInt(state.totalNumberOfSeats) - state.seatsSelected.length > 0) {
          state.seatLayoutData[type].seatsFinal[rowIndex][seatIndex]['SelectStatusFlag'] = true;
          state.seatLayoutData[type].seatsFinal[rowIndex][seatIndex].selectStatus = true;
          seat.seatIndex = seatIndex;
          seat.rowIndex = rowIndex;
          state.prevSeatType = type;
          state.seatsSelected.push(seat);
          seatIndex + 1;
          //check on right if available, if yes, select
          if (
            state.seatLayoutData[type].seatsFinal[rowIndex][parseInt(seatIndex) + 1] &&
            backward != "backward" &&
            state.seatLayoutData[type].seatsFinal[rowIndex][parseInt(seatIndex) + 1].seat_reserve_type_id == 1
          ) {
            //                        state.seatsSelected.push(seat);
            //                        state.seatData.SeatLayouts[classIndex].Seats[rowIndex][parseInt(seatIndex) + 1].SelectStatus = true;
            handlerSeatSelected(
              state.seatLayoutData[type].seatsFinal[rowIndex][parseInt(seatIndex) + 1],
              rowIndex,
              seatIndex + 1,
              originalIndex,
              backward,
              e,
              type
            );

            //check on left if available, if yes, select
          } else if (
            state.seatLayoutData[type].seatsFinal[rowIndex][parseInt(originalIndex) - 1] &&
            state.seatLayoutData[type].seatsFinal[rowIndex][parseInt(originalIndex) - 1].seat_reserve_type_id == 1
          ) {
            //                        state.seatsSelected.push(seat);
            //                        state.seatData.SeatLayouts[classIndex].Seats[rowIndex][parseInt(originalIndex) - 1].SelectStatus = true;
            handlerSeatSelected(
              state.seatLayoutData[type].seatsFinal[rowIndex][parseInt(originalIndex) - 1],
              rowIndex,
              originalIndex - 1,
              originalIndex - 1,
              "backward",
              e,
              type
            );
          }
        } else {
          if (firstTime == 1) {
            state.seatsSelected = [];
            handlerSeatSelected(
              state.seatLayoutData[type].seatsFinal[rowIndex][parseInt(seatIndex)],
              rowIndex,
              seatIndex,
              originalIndex,
              null,
              e,
              type
            );
          }
        }
      }
    }
  },
  NUMBER_OF_SEATS: (state, payload) => {
    state.totalNumberOfSeats = payload;
    state.seatsSelected.map(x => {
      state.netAmount = 0.00
      state.totalAmount = 0.00
      state.seatLayoutData[state.prevSeatType].seatsFinal[x.rowIndex][x.seatIndex].selectStatus = false;
      state.seatLayoutData[state.prevSeatType].seatsFinal[x.rowIndex][x.seatIndex].SelectStatusFlag = false;
    });
    state.prevSeatType = '';
    state.seatsSelected = [];

  },
  ON_RESERVE_SEATS: (state, payload) => {
    state.reservationId = payload
  },
  SET_PAYMENT_MODES: (state, payload) => {
    let modes = payload.Records.reverse();
    state.paymentModes = modes;
    state.selectedPaymentMode = modes.length > 0 ? modes[0] : {};
  },
  SET_SELECTED_PAYMENT_MODE: (state, payload) => {
    state.selectedPaymentMode = payload;
    // console.log('payload :', payload);
    store.dispatch("onSetAndSendLog", {
      action: `SELECTED ${payload.pm_payment_mode} mode for payment.`,
      page_name: "POS BOOKING Payment Mode",
      api_name: window.location.hash
    });
  }
}

const actions = {
  onResetDateStoreState: async ({
    dispatch,
    commit,
    state
  }, {
    ...payload
  }) => {
    await commit('ON_RESET_DATE_STORE_STATE');
  },
  SAVE_CURRENCY: (context, payload) => {
    context.commit('SET_CURRENCY', payload)
  },
  onChangeFlag: async ({
    dispatch,
    commit,
    state
  }, payload) => {
    commit('SET_TEMPLATES_FLAG', payload)
  },
  onCancelSelectedSeatsHandler: async ({
    commit
  }) => {
    console.log('herherherherherh :');
    commit('ON_REMOVE_ALL_SELECTED_SEATS');
  },
  GET_DATES: async ({
    dispatch,
    commit
  }) => {
    let {
      data
    } = await PosService.getAllDates()
    commit('SET_ALL_DATES', data)
    dispatch('SET_DATE', moment().format("DD-MM-YYYY"))
  },
  GET_MOVIE_DETAILS: async ({
    commit
  }, payload) => {
    let loader = vue.$loading.show();
    let {
      data
    } = await PosService.getMovieDetails(payload);
    loader.hide();
    if (data && data.status) {
      console.log('data MOVIE_DETAILS:', data);
      commit('SET_MOVIE_DETAILS', data)
    } else {
      vue.toasted.show(data.message || "Please try later");
    }
  },
  GET_ACTIVE_SEATS: async ({
    commit
  }, payload) => {
    let loader = vue.$loading.show();
    let {
      data
    } = await PosService.getActiveScreens(payload);
    loader.hide();
    if (data && data.status) {
      commit('SET_SCREENS', data)
    } else {
      vue.toasted.show(data.message || "Please try later");
    }
  },
  GET_SEATS_OCCUPANCY: async ({
    commit
  }, payload) => {
    // console.log('payload :', payload);
    let loader = vue.$loading.show();
    let {
      data
    } = await PosService.getOccupancyStatus(payload);
    loader.hide();
    if (data && data.status) {
      console.log('Seat Occupancy Data : ', data)
      commit('SET_SEAT_OCCUPANCY', data)
    } else {
      vue.toasted.show(data.message || "Please try later");
    }
  },

  SET_DATE: async (context, payload) => {
    let loader = vue.$loading.show();
    let {
      data
    } = await PosService.getAllShows({
      selectedDate: payload
    })
    context.commit('SET_SELECTED_DATE', payload)
    context.commit('SET_ALL_SHOWS', data)
    loader.hide();
    context.commit('SET_FORMAT', 'All Formats')
  },
  SAVE_SHOW_DETAILS: (context, payload) => {
    context.commit('SET_SHOW_DETAILS', payload)
  },
  GET_SEAT_LAYOUT: async (context, payload) => {
    console.log('payload :', payload);
    let loader = vue.$loading.show();
    let data = {}
    let seats = await PosService.PosGetSeatLayout({
      screen_id: payload.screen_id,
      ss_id: payload.ss_id,
      md_id: payload.md_id
    })
    data.seats = seats.data
    let seatType = await PosService.getSeatType({
      screen_id: payload.screen_id,
      ss_id: payload.ss_id,
      md_id: payload.md_id
    })

    data.seatType = seatType.data
    // console.log('data :', seats);
    loader.hide();
    if (seats && seats.status) {
      context.commit('SET_SEAT_LAYOUT', data)
    } else {
      vue.toasted.show(data.message || "Please try later");
    }
  },
  GET_Reserve_SEAT_LAYOUT: (context, payload) => {
    console.log('payload :', payload);
    context.commit('SET_SEAT_LAYOUT', payload)
  },
  FILTER_FORMAT: (context, payload) => {
    if (payload != 'All Formats') {
      context.commit('SET_FORMAT', payload)

    } else {
      context.commit('SET_FORMAT', 'All Formats')

    }
    context.commit('SET_ALL_SHOWS', 'filter')
  },
  SEAT_SELECTION: async (context, payload) => {
    // context.commit('SELECTED_SEATS', payload)
    console.log('payload :', payload);
    let loader = vue.$loading.show();
    PosService.reserveSeat({
      schedule_show_id: payload.scheduleShowId,
      seat_layout_id: payload.seat.sl_id,
      seat_reserve_type_id: payload.seat.seat_reserve_type_id,
      prev_seat_id: payload.seat.prev_seat_id
    }).then((response) => {
      const {
        data
      } = response;
      loader.hide();
      if (data && data.status) {
        context.commit('SELECTED_SEATS', payload)
      } else if (data && !data.status) vue.toasted.show(data.message || '!Something Went Wrong');
    }).catch((error) => {
      loader.hide();
      vue.toasted.show('Catch Error on insertBoxOfficeTransaction: ', error);
    });
  },
  SEAT_SELECTION_TRUE: (context, payload) => {
    context.commit('SEAT_SELECTED_TRUE', payload)
  },
  SEAT_SELECTION_FALSE: (context, payload) => {
    context.commit('SEAT_SELECTED_FALSE', payload)
  },
  SelectNumberOfSeats: (context, payload) => {
    console.log('payload NUMBER_OF_SEATS:', payload);
    context.commit('NUMBER_OF_SEATS', payload);
  },
  ADD_SHOW_BOOKING: async (context, payload) => {
    let loader = vue.$loading.show();
    // console.log('payload :', payload);
    // context.dispatch('insertBoxOfficeTransaction', payload)
    PosService.show_bookings(payload.show_booking)
      .then((response) => {
        const {
          data
        } = response;
        context.commit('SHOW_BOOKING_ID', data)
        return {
          showBookingId: data
        }
      })
      .then(async (showBookingId) => {
        const {
          data
        } = await PosService.show_seat_bookings({
          schedule_show_id: payload.show_booking.schedule_show_id,
          showBookingId: showBookingId.showBookingId.Records,
          selectedSeats: payload.show_seat_booking
        })
        if (data && data.status && data['ssb_id'] && data.ssb_id.length > 0) {
          context.dispatch('insertBoxOfficeTransaction', {
            ssb_id: data.ssb_id,
            showBookingId: showBookingId.showBookingId.Records,
            payload
          })
        }
        return {
          seatData: data,
          showBookingId,
          ssb_id: data
        }
      })
      .then(async ({
        showBookingId,
        seatData,
        ssb_id
      }) => {
        console.log('ssb_id :', ssb_id);
        const {
          data
        } = await PosService.updateBookingStatus({
          sb_id: showBookingId.showBookingId.Records,
          ssb_id: ssb_id.ssb_id
        })
        return {
          seatData,
          showBookingId
        }
      })
      .then(async ({
        seatData,
        showBookingId
      }) => {
        const {
          data
        } = await PosService.getBookingDetails({
          sb_id: showBookingId.showBookingId.Records
        });
        loader.hide();
        context.commit("SHOW_BOOKING_DETAILS", data)
      })
      .catch((error) => {
        loader.hide();
        console.log('error :', error);
      });
  },
  // Saif
  insertBoxOfficeTransaction: async ({
    dispatch,
    commit,
    state
  }, payload) => {
    let body = {
      ssb_id: payload.ssb_id,
      showBookingId: payload.showBookingId,
      cinema_id: state.showDetails.cine_id,
      screen_id: state.showDetails.screen_id,
      md_id: state.showDetails.md_id,
      t_show_date: state.showDetails.og_ss_start_date,
      t_show_time: state.showDetails.ss_start_show_time,
      schedule_show_id: state.showDetails.ss_id,
      t_net_amount: state.netAmount,
      t_gross_amount: state.totalAmount,
      payment_mode: state.selectedPaymentMode ? state.selectedPaymentMode : {},
      t_is_box_office: 'Y',
      additionalCharges: state.additionalCharges,
      //offer
      // offer: state.offerApplied
      offer: {
        t_offer_price: store.getters.getOfferPrice,
        oc_discount_value: store.getters.getOfferPrice,
        is_on_net_amount: null,
        is_on_net_amount: null,
        is_offer_for: store.getters.getOfferFor,
        is_offer_applied: Object.keys(store.getters.getSelectedOffer).length > 0 ? true : false,
        ...store.getters.getSelectedOffer
      }
    };
    if (store.getters.getOfferFor === 'offersAndPromotions') {
      body.offer['is_on_net_amount'] = store.getters.getSelectedOffer['offer_applicable_on_net_amount'] || 'N';
      body.offer['is_on_gross_amount'] = store.getters.getSelectedOffer['offer_applicable_on_gross_amount'] || 'N';
    } else if (store.getters.getOfferFor === "vouchers") {
      body.offer['is_on_net_amount'] = store.getters.getSelectedOffer['voucher_redeem_on_net'] || 'N';
      body.offer['is_on_gross_amount'] = store.getters.getSelectedOffer['voucher_redeem_on_gross'] || 'N';
    }
    console.log('body :', body);
    PosService.PosBoxOfficeTransactions(body)
      .then((response) => {
        const {
          data
        } = response;
        if (data && data.status) { } else vue.toasted.show(data.message || 'Opps! Something went wrong');
      }).catch((error) => {
        vue.toasted.show('Catch Error on insertBoxOfficeTransaction: ', error);
      });
  },
  // Saif
  GET_ADDITIONAL_CHARGES: async (context, payload) => {
    const {
      data
    } = await PosService.getAdditionalCharges({
      selectedSeats: payload.selected_seats,
      showDetails: payload.showDetails
    })
    console.log('data additionalcharges :', data);
    context.commit("GET_ADITIONAL_CHARGES", data);
  },

  NEW_ADDITIONAL_CHARGES: async (context, payload) => {

  },

  FETCH_BOOKING_DETAILS: async (context, payload) => {
    // console.log('payload :', payload);
    let loader = vue.$loading.show();
    const {
      data
    } = await PosService.pickupTicket({
      booking_id: payload
    });
    loader.hide();
    context.commit("TICKET_DETAILS", data)
  },
  SET_CURRENT_TIME: (context, payload) => {
    context.commit("CURRENT_TIME")
  },
  GET_SHIFT_DETAILS: ({ commit }, payload) => {
    commit('SET_SHIFT_DETAILS', payload)
  },
  SET_CASH_INPUT: (context, payload) => {
    context.commit('GET_CASH_INPUT', payload)
  },
  SET_TOTAL_CASH_COLLECTED: (context, payload) => {
    context.commit('GET_TOTAL_CASH_COLLECTED', payload)
  },
  SET_TOTAL_CARD_AMOUNT: (context, payload) => {
    context.commit('GET_TOTAL_CARD_AMOUNT', payload)
  },
  SET_CURRENT_SCREEN: (context, payload) => {
    context.commit("CURRENT_SCREEN", payload)
  },
  MAKE_DATA_EMPTY: (context, payload) => {
    context.commit("SET_EMPTY_DATA")
  },
  MAKE_SEAT_BOOKING_DETAILS_EMPTY: async (context, payload) => {
    console.log('payload :', payload);
    let request_payload = {
      reservation_id: state.reservationId
    }
    await PosService.PosReleaseSeats(request_payload)
      .then((response) => {
        store.dispatch("onSetAndSendLog", {
          action: `Selected seats     ${this.seatNames}     were released as user clicked back button.`,
          page_name: "pos booking",
          api_name: ""
        });
        const { data } = response;
        if (data.status) {
          vue.toasted.show(data.message);
        } else {
          vue.toasted.show(data.message);
        }
      }).catch((err) => {
        vue.toasted.show('Catch error in release seats', err);

      });
    await context.commit("SET_EMPTY_SEAT_BOOKING_DETAILS")
  },
  SET_SCHEDULE_SHOW_ID: (context, payload) => {
    context.commit('GET_SCHEDULE_SHOW_ID', payload)
  },
  //For Offers
  SET_TOTAL_AMOUNT: (context, payload) => {
    context.commit('GET_TOTAL_AMOUNT', payload)
  },
  SET_OFFER: (context, payload) => {
    context.commit('GET_OFFER', payload)
  },
  seatHandler: async (context, payload) => {
    await context.commit('SELECT_SEAT_HANDLDER', payload)
    let newPayload = {
      seat: payload.seat,
      seatIndex: payload.seatIndex,
      rowIndex: payload.rowIndex,
      classIndex: payload.type,
    }
    await context.commit('SELECTED_SEATS', newPayload)
  },
  RESERVE_SEATS: async (context, payload) => {
    this.seatNames = payload.seat_layout_id.map(x => { return x.sl_seat_name })
    // console.log('seatNames :', seatNames);
    let seats = payload.seat_layout_id.map(x => { return x.sl_id })
    console.log('seats :', seats.length, state.totalNumberOfSeats);
    if (seats.length > 0) {
      let loader = vue.$loading.show();
      await PosService.PosReserveSeat({
        seat_layout_id: seats,
        schedule_show_id: payload.schedule_show_id,
        reservation_id: payload.reservation_id
      }).then((response) => {
        const { data } = response;
        loader.hide();
        if (data.status) {
          store.dispatch("onSetAndSendLog", {
            action: `Selected seats     ${this.seatNames}    were reserved.`,
            page_name: "pos booking",
            api_name: ""
          });
          context.commit('ON_RESERVE_SEATS', payload.reservation_id)
          context.dispatch('GET_PAYMENT_MODES');
          vue.toasted.show(data.message);
        } else {
          context.dispatch('GET_SEAT_LAYOUT', state.showDetails);
          context.commit('NUMBER_OF_SEATS', state.totalNumberOfSeats);
          vue.toasted.show(data.message);
        }
      }).catch((err) => {
        loader.hide();
        vue.toasted.show('Catch error in reserve seat');
      });
    } else {
      vue.toasted.show('Please select at least one seat');
    }

  },
  GET_PAYMENT_MODES: async (context, payload) => {
    let request_payload = {
      tableName: "ms_payment_modes",
      pm_is_active: "Y"
    };
    await MasterService.GetActiveList(request_payload)
      .then(response => {
        const { data } = response;
        if (data && data.status && data.Records.length > 0) {
          context.commit('SET_PAYMENT_MODES', data);
        } else {
          vue.toasted.show(data.message);
        }
      })
      .catch(error => {
        vue.toasted.show(error);
      });
  },
  setPaymentMode: (context, payload) => {
    context.commit('SET_SELECTED_PAYMENT_MODE', payload);

  },
  BOOK_SEATS: async (context, payload) => {
    console.log('ReservationId :', payload);
    let loader = vue.$loading.show();
    await PosService.PosBookSeats({
      reservation_id: payload,
      payment_mode_id: state.selectedPaymentMode ? state.selectedPaymentMode.pm_id : 1,
    }).then((response) => {
      const { data } = response;
      console.log('data :', data);
      if (data.status) {

        context.commit('SHOW_BOOKING_ID', { Records: data.bookingID })
        if (data && data.status && data['ssb_id'] && data.ssb_id.length > 0) {
          context.dispatch('insertBoxOfficeTransaction', {
            ssb_id: data.ssb_id,
            showBookingId: data.bookingID,
            payload
          })

        }
        return {
          showBookingId: data.bookingID,
          ssb_id: data.ssb_id,
        }
      } else {

        vue.toasted.show(data.message);
      }
    })
      .then(async ({
        showBookingId,
        ssb_id
      }) => {
        let ssb_ids = ssb_id.map(x => { return x.ssb_id });
        const {
          data
        } = await PosService.updateBookingStatus({
          sb_id: showBookingId,
          ssb_id: ssb_ids
        })
        return {
          showBookingId
        }
      })
      .then(async ({
        showBookingId
      }) => {
        const {
          data
        } = await PosService.PosBookingDetails({
          show_booking_id: showBookingId
        });
        context.commit("SHOW_BOOKING_DETAILS", data)

        store.dispatch("onSetAndSendLog", {
          action: `Generate bill is clicked and ${this.seatNames} got booked with show booking id: ${showBookingId} `,
          page_name: "pos booking",
          api_name: ""
        });
      })
      .catch((err) => {
        // store.dispatch("onSetAndSendLog", {
        //     action: `ERROR occured in booking : ${err} `,
        //     page_name: "pos booking",
        //     api_name: ""
        // });
        console.log('err :', err);
        vue.toasted.show('Catch error in reserve seat');
      });
    loader.hide();
  },
  GET_HARDWARE_NAME: (context, payload) => {
    PosService.GetHardwareName({ use_id: payload }).then(response => {
      console.log('response :', response);
      const { data } = response;

      if (data && data.status) {
        context.commit('SET_HARDWARE_NAME', data.Records[0]);
      }
    }).catch((err) => {
      vue.toasted.show(err);
    });
  }
}

const getters = {
  DATES: state => state.dates,
  SELECTED_DATE: state => state.selectedDate,
  SHOW_TIMES: state => state.showTimes,
  SHOW_DATES: state => state.visibleShowsDates,
  SHOW_SCREENS: state => state.visibleShowsScreens,
  SHOW_LANGUAGE: state => state.visibleShowsLanguage,
  SEAT_LAYOUT_DATA: state => state.seatLayoutData,
  SELECTED_SEATS: state => state.seatsSelected,
  SCHEDULE_SHOW_ID: state => state.scheduleShowId,
  TOTAL_AMOUNT: state => state.totalAmount,
  SHOW_USER_INFO: state => state.userINFO,

  GET_CURRENCY: state => state.currency,
  SHOW_DETAILS: state => state.showDetails,
  SHOW_BOOKING_ID: state => state.showBookingId,
  BOOKING_DETAILS: state => state.bookingDetails,
  TICKET_DETAILS: state => state.ticketakDetails,
  enterTicketDetails: state => state.enterTicketDetails,
  showTicketInfo: state => state.showTicketInfo,
  CURRENT_DATE: state => state.currentDate,
  CURRENT_TIME: state => state.currentTime,
  ADDITIONAL_CHARGES: state => state.additionalCharges,
  MOVIE_DETAILS: state => state.movieDetails,
  SEATS_OCCUPANCY: state => state.seatOccupancy,
  ACTIVE_SCREENS: state => state.screens,
  CURRENT_SCREEN: state => state.currentScreen,
  NET_AMOUNT: state => state.netAmount,
  CASH_INPUT: state => state.cashInput,
  TOTAL_CASH_COLLECTED: state => state.totalCashCollected,
  TOTAL_CARD_AMOUNT: state => state.totalCardAmount,
  SHIFT_DETAILS: state => state.shiftDetails,
  OFFER_DETAILS: state => state.offerApplied,
  // 
  totalNumberOfSeats: state => state.totalNumberOfSeats,
  getScreenPosNames: state => state.showDetails.screen_name,
  reservationId: state => state.reservationId,
  paymentModes: state => state.paymentModes,
  selectedPaymentMode: state => state.selectedPaymentMode,
  gethardwarename: state => state.HardwareName
}

export default {
  state,
  mutations,
  actions,
  getters
}